import React from "react"

import { ThemeContextProvider } from "../util"
import Snowfall from 'react-snowfall'

import { Helmet } from "react-helmet"
import { Campaign, Navigation, Hero, Lunch, Catering, Footer, Quotation, Contact } from "./../sections"

// function getWeekNumber(date: Date): number {
//   // Create a copy of the date object
//   const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  
//   // Set the time to the start of the day (UTC)
//   newDate.setUTCDate(newDate.getUTCDate() + 4 - (newDate.getUTCDay() || 7));
  
//   // Get the first day of the year
//   const yearStart = new Date(Date.UTC(newDate.getUTCFullYear(), 0, 1));
  
//   // Calculate the week number
//   const weekNumber = Math.ceil((((newDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  
//   return weekNumber;
// }

// const weekNumber: number = getWeekNumber(new Date());

// const menus: { [key: number]: string } = {
//   // 25: 'https://assets.ctfassets.net/4tspwvume9yz/1i1QB41NNt4A5eTjnkngJF/516c94d434a4a1f630581f14e3915310/Lunchmeny__2024_25_Pdf.pdf',
//   // 26: 'https://assets.ctfassets.net/4tspwvume9yz/34IdrWGbAnyKiZ324njqPr/388ffafa402670144903d647cf503d71/Lunchmeny__2024_26_Pdf.pdf',
//   // 27: 'https://assets.ctfassets.net/4tspwvume9yz/6nTShv8LK3xyHJMOZHTBfX/e384835d381e746a31b7a2548ed1014d/Lunchmeny__2024_27_Pdf.pdf',
//   // 28: 'https://assets.ctfassets.net/4tspwvume9yz/6cgKsUnYgs4icGzy7HsljU/f87c1f85fde1bf08328cb5449f55d3a5/Lunchmeny__2024_28_Pdf.pdf',
//   // 29: 'https://assets.ctfassets.net/4tspwvume9yz/4QznHlEZ1llyoX6jJnOpTp/22bfdebf5b4a2e588793db4832b8d189/Lunchmeny__2024_29_Pdf.pdf',
//   // 30: 'https://assets.ctfassets.net/4tspwvume9yz/5624MHAp0ZzavgkRFLjFbc/25c234c6c36d87a4e6d2a5264bb057e7/Lunchmeny__2024_30_pdf.pdf',
//   31: 'https://assets.ctfassets.net/4tspwvume9yz/5KSZzfdHt5z6z8RmiMse48/e8fcf4a13c29ad4af7f03388f9120106/Lunchmeny__2024_31_Pdf.pdf',
//   32: 'https://assets.ctfassets.net/4tspwvume9yz/4dV7QLJs4HvcoF4JFl5RhO/d3bf90d63c334a98c82dd1a678245765/Lunchmeny__2024_32_Pdf.pdf',
//   33: 'https://assets.ctfassets.net/4tspwvume9yz/22gHv4EUEiqUMtf6GA6WPP/67d16473340c2be6ec05748a9d0afc70/Lunchmeny__2024_33_Pdf.pdf',
// };

// console.log('weekNumber', weekNumber);

export const LunchmenyURL = '';

const IndexPage: React.FC = () => {
  const [showSnow, setShowSnow] = React.useState(false);
  
  React.useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    console.log(isBrowser)
    if(isBrowser) setShowSnow(true)
  }, []);

  return (
    <ThemeContextProvider>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <title>Freddes mat - Catering och Lunch i Härnösand</title>
        <meta name="description" content="Freddes mat - Catering och Lunch i Härnösand" />
        <meta name="keywords" content="Lunch, Catering, Lokal, Eventlokal, Veckans lunch, Härnösand" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <script
          async
          type="text/javascript"
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=JxpwK7"
        ></script>
        <style type="text/css">{`
             html {
               font-size: 100%;
               margin: 0;
               padding: 0;
               background:#fdfbfa;
             }
             body {
               padding: 0;
               margin: 0;
               background:#fdfbfa;
             }
             * {
               font-family: 'Roboto Condensed', sans-serif;
             }
        `}</style>
      </Helmet>
      {showSnow && (<Snowfall />)} 
      <Navigation />
      <Hero     id="start" />
      <Campaign id="campaign" /> 
      <Lunch    id="lunch" />
      <Catering id="catering" />
      <Contact  id="contact" />
      <Footer /> 
    </ThemeContextProvider>
  )
}

export default IndexPage
